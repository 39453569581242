<template>
  <div
    class="login-base"
    :style="BgStyle"
  >
    <div
      class="login-body"
      :style="LeftBgStyle"
    >
      <div class="login-reg-base">
        <div :class="['login-reg', currentType]">
          <div class="forget-pass-content">
            <div class="login-top">
              <p>统一认证中心</p>
              <i class="el-icon-lock"></i>
            </div>
            <div class="form-main">
              <div class="login-header">
                <ul>
                  <li class="active">忘记密码</li>
                </ul>
              </div>
              <div class="login-form-body">
                <div :class="`type-${currentForgetPwdTag}`">
                  <div class="login-form-item">
                    <el-form
                      :model="forgetPwdForm"
                      :rules="forgetPwdRules"
                      ref="forgetPwdForm"
                      label-width="0"
                    >
                      <el-form-item prop="phone">
                        <el-input
                          v-model="forgetPwdForm.phone"
                          placeholder="请输入手机号码找回密码"
                        ></el-input>
                      </el-form-item>
                      <el-form-item prop="smsCode">
                        <el-input
                          v-model="forgetPwdForm.smsCode"
                          placeholder="短信验证码"
                          style="width: calc(100% - 290px); margin-right: 20px;"
                          @keyup.enter.native="postPhonePwdOne"
                        ></el-input>
                        <el-button
                          type="primary"
                          @click="sendCode(4)"
                          style="float: right; width: 270px;"
                          :disabled="isOkForgetPwdPhone || times>0"
                          :loading="sendLoading"
                        ><span v-if="times>0">{{times}}s后可再次发送</span><span v-else-if="!sendLoading">发送验证码</span><span v-else>发送中...</span></el-button>
                        <p
                          v-if="showNoRegTip"
                          class="info-1"
                        >检测到您未注册，请点击<a
                            href="javascript:;"
                            @click="toReg"
                          >注册</a></p>
                      </el-form-item>
                      <el-form-item
                        prop="userId"
                        v-show="accountList && accountList.length>1"
                      >
                        <el-select
                          v-model="forgetPwdForm.userId"
                          placeholder="请选择账号"
                          style="width: 100%"
                          popper-class="ma-select"
                        >
                          <el-option
                            :label="item.realname"
                            :value="item.id"
                            v-for="item in accountList"
                            :key="item.id"
                          >
                            <div class="account-item" @click="changeAccount('forgetPwdForm', item.id)">
                              <el-image
                                :src="item.avatar"
                                class="user-logo"
                              >
                                <div
                                  slot="error"
                                  class="image-slot"
                                >
                                  <img src="~@/assets/image/default-avatar.png" />
                                </div>
                              </el-image>
                              <span>{{item.realname}}</span>
                            </div>
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="login-form-item">
                    <el-form
                      :model="forgetPwdForm"
                      :rules="forgetPwdRules"
                      ref="forgetPwdFormTwo"
                      label-width="0"
                    >
                      <el-form-item prop="password">
                        <el-input
                          type="password"
                          v-model="forgetPwdForm.password"
                          placeholder="请输入新密码"
                        ></el-input>
                      </el-form-item>
                      <el-form-item prop="confirmPassword">
                        <el-input
                          type="password"
                          v-model="forgetPwdForm.confirmPassword"
                          placeholder="请重新输入新密码"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </div>
              <a
                style="color:#0081ef;font-size: 13px;"
                href="javascript:;"
                @click="currentForgetPwdTag = 1"
                v-if="currentForgetPwdTag == 2"
              >返回上一步</a>
              <a
                href="javascript:;"
                :class="['submit']"
                @click="postPhonePwdOne"
              >{{currentForgetPwdTag == 1 ? '下一步' : '提交'}}</a>
              <p class="info-2">已有账号？<a
                  href="javascript:;"
                  @click="toLogin"
                >立即登录</a></p>
            </div>
          </div>
          <!------>
          <div class="login-content">
            <div class="login-top">
              <p>统一认证中心</p>
              <i class="el-icon-lock"></i>
            </div>
            <div class="form-main">
              <div class="login-header">
                <ul v-if="currentTag < 3">
                  <li
                    @click="currentTag = 1"
                    :class="{active: currentTag == 1}"
                  >账号登录</li>
                  <li
                    @click="currentTag = 2"
                    :class="{active: currentTag == 2}"
                  >短信登录</li>
                </ul>
              </div>
              <div class="login-form-body">
                <div :class="`type-${currentTag}`">
                  <div class="login-form-item">
                    <el-form
                      :model="loginForm"
                      :rules="rules"
                      ref="loginForm"
                      label-width="0"
                    >
                      <el-form-item prop="username">
                        <el-input
                          v-model="loginForm.username"
                          placeholder="账号"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        v-show="accountList && accountList.length>1"
                        prop="userId"
                      >
                        <el-select
                          v-model="loginForm.userId"
                          placeholder="请选择账号"
                          style="width: 100%"
                          popper-class="ma-select"
                        >
                          <el-option
                            :label="item.realname"
                            :value="item.id"
                            v-for="item in accountList"
                            :key="item.id"
                          >
                            <div class="account-item"  @click="changeAccount('loginForm', item.id)">
                              <el-image
                                :src="item.avatar"
                                class="user-logo"
                              >
                                <div
                                  slot="error"
                                  class="image-slot"
                                >
                                  <img src="~@/assets/image/default-avatar.png" />
                                </div>
                              </el-image>
                              <span>{{item.realname}}</span>
                            </div>
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item prop="password">
                        <el-input
                          type="password"
                          v-model="loginForm.password"
                          :show-password="true"
                          placeholder="输入密码"
                          @keyup.enter.native="login"
                        ></el-input>
                      </el-form-item>
                    </el-form>

                    <p class="info-1"><a
                        @click="toForgetPass"
                        href="javascript:;"
                      >忘记密码？</a></p>
                    <a
                      href="javascript:;"
                      class="submit"
                      :style="(accountList && accountList.length>1)?{}:{marginTop: '75px'}"
                      @click="login"
                    >登录</a>
                    <p class="info-2">还没有账号？
                      <a
                        href="javascript:;"
                        @click="toReg"
                      >立即注册</a>
                    </p>
                  </div>
                  <div class="login-form-item">
                    <el-form
                      :model="loginForm"
                      :rules="rules"
                      ref="loginOtherForm"
                      label-width="0"
                    >
                      <el-form-item prop="username">
                        <el-input
                          v-model="loginForm.username"
                          placeholder="账号"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        prop="userId"
                        v-show="accountList && accountList.length>1"
                      >
                        <el-select
                          v-model="loginForm.userId"
                          placeholder="请选择账号"
                          style="width: 100%"
                          popper-class="ma-select"
                        >
                          <el-option
                            :label="item.realname"
                            :value="item.id"
                            v-for="item in accountList"
                            :key="item.id"
                          >
                            <div class="account-item" @click="changeAccount('loginForm1', item.id)">
                              <el-image
                                :src="item.avatar"
                                class="user-logo"
                              >
                                <div
                                  slot="error"
                                  class="image-slot"
                                >
                                  <img src="~@/assets/image/default-avatar.png" />
                                </div>
                              </el-image>
                              <span>{{item.realname}}</span>
                            </div>
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <!-- <el-form-item prop="verCode">
                      <el-input v-model="loginForm.verCode" placeholder="图片验证码" style="width: 165px;margin-right: 20px;"></el-input>
                      <img :src="``" style="width: 140px; height: 40px;"/>
                    </el-form-item> -->
                      <el-form-item prop="smsCode">
                        <el-input
                          v-model="loginForm.smsCode"
                          placeholder="短信验证码"
                          style="width: calc(100% - 290px);margin-right: 20px;"
                          @keyup.enter.native="login"
                        ></el-input>
                        <el-button
                          type="primary"
                          @click="sendCode(2)"
                          style="float: right; width: 270px;"
                          :disabled="isOkLoginPhone || times>0"
                          :loading="sendLoading"
                        ><span v-if="times>0">{{times}}s后可再次发送</span><span v-else-if="!sendLoading">发送验证码</span><span v-else>发送中...</span></el-button>
                      </el-form-item>
                    </el-form>

                    <p class="info-1"><a
                        @click="toForgetPass"
                        href="javascript:;"
                      >忘记密码？</a></p>
                    <a
                      href="javascript:;"
                      class="submit"
                      @click="login"
                    >登录</a>
                    <p class="info-2">还没有账号？
                      <a
                        href="javascript:;"
                        @click="toReg"
                      >立即注册</a>
                    </p>
                  </div>
                  <!-- 已登录 -->
                  <div class="login-form-item logined">
                    <template v-if="userInfo">
                      <p class="logined-p"><img
                          src="~@/assets/image/round-link.png"
                          style="width: 30px; height: 30px;"
                        /> 检测到您已登录的亿启编账号</p>
                      <div class="user-info">
                        <el-image
                          :src="userInfo.avatar"
                          class="user-logo"
                        >
                          <div
                            slot="error"
                            class="image-slot"
                          >
                            <img src="~@/assets/image/default-avatar.png" />
                          </div>
                        </el-image>
                        <div>{{userInfo.realname}}</div>
                        <div><a
                            href="javascript: void(0)"
                            class="switch-account"
                            @click="currentTag = 1"
                          >切换账号</a></div>
                        <a
                          href="javascript:;"
                          class="submit"
                          @click="quickLogin"
                        >快速登录</a>
                      </div>
                    </template>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="reg-content">
            <div class="login-top">
              <p>统一认证中心</p>
              <i class="el-icon-lock"></i>
            </div>
            <div class="form-main">
              <div class="login-header">
                <ul>
                  <li class="active">注册账号</li>
                </ul>
              </div>
              <el-form
                :model="regForm"
                :rules="regRules"
                ref="regForm"
                label-width="0"
              >
                <el-form-item prop="phone">
                  <el-input
                    v-model="regForm.phone"
                    placeholder="手机号码"
                  ></el-input>
                </el-form-item>
                <!-- <el-form-item prop="verCode">
                  <el-input v-model="regForm.verCode" placeholder="图片验证码" style="width: 165px;margin-right: 20px;"></el-input>
                  <img :src="``" style="width: 140px; height: 40px;"/>
                </el-form-item> -->
                <el-form-item prop="smsCode">
                  <el-input
                    v-model="regForm.smsCode"
                    placeholder="短信验证码"
                    style="width: calc(100% - 290px);margin-right: 20px;"
                  ></el-input>
                  <el-button
                    type="primary"
                    @click="sendCode(1)"
                    style="float: right; width: 270px;"
                    :disabled="isOkRegPhone || times>0"
                    :loading="sendLoading"
                  ><span v-if="times>0">{{times}}s后可再次发送</span><span v-else-if="!sendLoading">发送验证码</span><span v-else>发送中...</span></el-button>
                </el-form-item>
                <el-form-item prop="nickname">
                  <el-input
                    v-model="regForm.nickname"
                    placeholder="请输入昵称"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    type="password"
                    v-model="regForm.password"
                    :show-password="true"
                    placeholder="输入密码"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="confirmPassword">
                  <el-input
                    type="password"
                    v-model="regForm.confirmPassword"
                    :show-password="true"
                    placeholder="确认密码"
                    @keyup.enter.native="reg"
                  ></el-input>
                </el-form-item>
              </el-form>
              <a
                href="javascript:;"
                class="submit"
                @click="reg"
              >注册</a>
              <p class="info-2">已有账号？<a
                  href="javascript:;"
                  @click="toLogin"
                >立即登录</a></p>

              <p class="info-3">未注册手机验证后自动登录，注册即代表同意<a
                  href="javascript: void(0)"
                  @click="showAgreement"
                >《用户协议》</a><a
                  href="javascript: void(0)"
                  @click="showAgreement"
                >《隐私保护指引》</a></p>
            </div>

          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title=""
      :visible.sync="agreementDialogVisible"
      width="80%"
      center
    >
      <Agreement></Agreement>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="agreementDialogVisible = false"
        >确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { login, reg, sendCode, forgetPwd, getTicket, checkPhone, getAccounts } from "@/api/user"
import { ACCESS_TOKEN } from '@/store/mutation-types';
import Vue from 'vue';
import { getUserInfo } from '@/utils/auth';
import { removeURLParameter } from '@/utils'
import Agreement from './Agreement.vue'
import { mapState } from 'vuex';

const phoneValidator = (rule, value, callback) => {
  if (!/^1[0-9]{10}$/.test(value)) {
    callback(new Error('请输入正确的手机号'));
  } else {
    callback();
  }
};
const phoneValidator2 = (rule, value, callback) => {
  checkPhone(value).then(res => {
    if (res.code == 200) {
      callback()
    }
    else {
      callback(new Error(res.message))
    }
  })
}

export default {
  name: 'User-login',
  components: { Agreement },
  data() {
    return {
      LeftBgStyle: {},
      BgStyle: {},
      mutiAccountForm: {},
      accountList: null,
      agreementDialogVisible: false,
      agreementClickTimes: 0,
      sendLoading: false,
      times: 0,
      currentType: this.$route.query.type || 'login',
      redirectUri: this.$route.query.redirect_uri || '',
      from: this.$route.query.from || '',
      currentTag: 1,
      currentForgetPwdTag: 1,
      setI: null,
      showNoRegTip: false,
      loginForm: {
        username: '',
        smsCode: '',
        password: '',
        userId:''
        // verCode: '11'
      },
      regForm: {
        phone: '',
        smsCode: '',
        nickname: '',
        password: '',
        confirmPassword: '',
        orgCode: '',
        // verCode: '11'
      },
      forgetPwdForm: {
        phone: '',
        smsCode: '',
        password: '',
        confirmPassword: '',
        prevToken: '',
        userId:''
      },
      forgetPwdRules: {
        phone: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          // { validator: phoneValidator, trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        password: [
          {
            validator: (rule, value, callback) => {
              if (this.currentForgetPwdTag == 2 && value == '') {
                callback(new Error('请输入密码'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            validator: (rule, value, callback) => {
              if (this.currentForgetPwdTag == 2 && value == '') {
                callback(new Error('请重新输入密码'));
              } else if (this.currentForgetPwdTag == 2 && value !== this.forgetPwdForm.password) {
                callback(new Error('两次密码不一致'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
      },
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          // { validator: phoneValidator, trigger: 'blur' }
        ],
        password: [
          {
            validator: (rule, value, callback) => {
              if (this.currentTag == 1 && value == '') {
                callback(new Error('请输入密码'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
        userId: [
          // { required: true, message: '请选择需要登录的账号', trigger: 'blur' }
          {
            validator: (rule, value, callback) => {
              if (this.currentTag == 1 && !this.loginForm.userId) {
                if (this.accountList && this.accountList.length > 1) { callback(new Error('请选择需要登录的账号')); }
                else callback()
              }
              else {
                callback()
              }
            },
            trigger: "blur"
          }
        ],
        smsCode: [
          {
            validator: (rule, value, callback) => {
              if (this.currentTag == 2 && value == '') {
                callback(new Error('请输入验证码'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ]
      },
      mutiAccountRules: {
        phone: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        userId: [
          { required: true, message: '请选择需要登录的账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
          // { validator: (rule, value, callback) => {
          //   if (this.currentTag == 1 && value == '') {
          //     callback(new Error('请输入密码'));
          //   } else {
          //     callback();
          //   }
          // }, trigger: 'blur' }
        ]
      },
      regRules: {
        phone: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { validator: phoneValidator, trigger: 'blur' }
          // { validator: phoneValidator2, trigger: 'blur' }
        ],
        smsCode: [
          {
            validator: (rule, value, callback) => {
              if (value == '') {
                callback(new Error('请输入验证码'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          },
        ],
        nickname: [
          {
            validator: (rule, value, callback) => {
              if (value == '') {
                callback(new Error('请输入昵称'));
                // } else if (!/^[^x00-xff]{1,6}$/.test(value)) {
                //   callback(new Error('请输入最多6个中文'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          },
          { max: 10, message: '长度在10个字以内', trigger: 'blur' }

        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.regForm.password) {
                callback(new Error('密码不一致'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ],
      }
    };
  },
  watch: {
    currentTag() {
      this.loginForm = {
        username: '',
        smsCode: '',
        password: ''
      }
      this.$refs.loginForm.resetFields();
      this.$refs.loginOtherForm.resetFields();
      clearInterval(this.setI);
      this.times = 0;
    },
    currentForgetPwdTag() {
      this.$refs.forgetPwdFormTwo.resetFields();
    },
    currentType() {
      this.currentTag = 1;
      this.$refs.loginForm.resetFields();
      this.$refs.loginOtherForm.resetFields();
      this.loginForm = {
        username: '',
        smsCode: '',
        password: ''
      }
      this.regForm = {
        phone: '',
        smsCode: '',
        nickname: '',
        password: '',
        confirmPassword: '',
        orgCode: ''
      }
      this.forgetPwdForm = {
        phone: '',
        smsCode: '',
        password: '',
        confirmPassword: '',
        prevToken: ''

      }
      this.$refs.regForm.resetFields();
      this.$refs.forgetPwdForm.resetFields();
      this.$refs.forgetPwdFormTwo.resetFields();
      clearInterval(this.setI);
      this.times = 0;
      this.sendLoading = false;
      this.showNoRegTip = false;
    },
    $route(newRoute) {
      console.log("before:", this.redirectUri)
      this.currentType = newRoute.query.type || 'login'
      this.redirectUri = newRoute.query.redirect_uri || ''
      console.log("after:", this.redirectUri)
    },
    'loginForm.username': {
      handler(val) {
        this.initAccountList()
      }
    },
    'forgetPwdForm.phone': {
      handler(val) {
        this.initAccountList(true)
      }
    },
    titleConfig: {
      handler(val) {
        let bg = val['banner9']
        let left = val['banner10']
        if (bg) this.BgStyle = { backgroundImage: `url(${bg})` }
        if (left) this.LeftBgStyle = { backgroundImage: `url(${left})` }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    isOkLoginPhone() {
      return !(/^1[0-9]{10}$/.test(this.loginForm.username));
      // return !(/^1[0-9]{10}$/.test(this.loginForm.username) && this.loginForm.verCode);
    },
    isOkRegPhone() {
      return !(/^1[0-9]{10}$/.test(this.regForm.phone));
      // return !(/^1[0-9]{10}$/.test(this.regForm.phone) && this.regForm.verCode);
    },
    isOkForgetPwdPhone() {
      return !(/^1[0-9]{10}$/.test(this.forgetPwdForm.phone));
      // return !(/^1[0-9]{10}$/.test(this.regForm.phone) && this.regForm.verCode);
    },
    userInfo: function () {
      return getUserInfo();
    },
    ...mapState({
      titleConfig: (state) => state.siteConfigs,
    })
  },
  created() {
    // 另外的平台的登出
    // const isLogout = this.$route.query.isLogout;
    // if(isLogout) return that.$store.dispatch('LogoutUser');
    const token = Vue.ls.get(ACCESS_TOKEN);
    const cookie = Vue.prototype.getCookie('EQBSESSION')
    if (this.from) this.redirectUri = this.getQueryVariable(window.location.href, 'redirect_uri')
    //.replace(/\%3A/g,":").replace(/\%2F/g,"/").replace(/\%3F/g,"?").replace(/\%3D/g,"=").replace(/\%3D/g,"=")
    console.log("uri:", this.redirectUri)
    //if(this.from == "ksxt") this.redirectUri = this.redirectUri + '#/'

    if (cookie && token) {
      this.currentTag = 3
    }
  },
  methods: {
    changeAccount(formObj, id){
      this.$set(formObj=="forgetPwdForm"?this.forgetPwdForm:this.loginForm, 'userId', id)
      this.$forceUpdate();
    },
    dbLogin() {
      // if(!this.mutiAccountForm.username) return this.$message.warning("请选择登录的账号")
      // if(!this.mutiAccountForm.password) return this.$message.warning("请输入密码")
      this.$refs['mutiAccountForm1'].validate(isOk => {
        if (isOk) {
          this.loginApi(this.mutiAccountForm)
        }
      })


    },
    showAgreement() {
      this.agreementDialogVisible = true
      this.agreementClickTimes++
    },
    removeURLParameter1111(url, parameter) {
      var urlparts = url.split('?');
      if (urlparts.length >= 2) {
        //参数名前缀
        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);

        //循环查找匹配参数
        for (var i = pars.length; i-- > 0;) {
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
            //存在则删除
            pars.splice(i, 1);
          }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
      }
      return url;
    },
    //获取url参数
    getQueryVariable(url, variable) {
      url = decodeURIComponent(url)
      let index = url.indexOf('?')
      if (index == -1) return url
      var query = url.substring(index + 1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        let j = vars[i].indexOf('=')
        var pn = vars[i].substring(0, j)
        var pv = vars[i].substring(j + 1)
        if (pn == variable) { return pv; }
      }
      return (url);
    },
    setQueryVariable(url, arg, arg_val) {
      if (arg == 'ticket' && this.getQueryVariable(url, 'ticket')) url = removeURLParameter(url, 'ticket')
      console.log("-------url:", url)
      var pattern = arg + '=([^&]*)';
      var replaceText = arg + '=' + arg_val;
      if (url.match(pattern)) {
        var tmp = '/(' + arg + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
      } else {
        if (url.match('[\?]')) {
          return url + '&' + replaceText;
        } else {
          return url + '?' + replaceText;
        }
      }
    },
    //快速登录
    quickLogin() {
      //protal 用户信息储存
      this.$store.dispatch('LoginUser', { token: Vue.ls.get(ACCESS_TOKEN), userInfo: this.userInfo })

      //如果是protal就直接跳转
      if (this.redirectUri && this.redirectUri.indexOf(process.env.VUE_APP_PROTAL) > -1) return location.href = this.redirectUri
      if (!this.redirectUri && location.href == `${process.env.VUE_APP_PROTAL}/login`) return location.href = process.env.VUE_APP_PROTAL

      getTicket().then(res => {
        //this.redirectUri += this.redirectUri.indexOf("?")>-1 ? "&ticket="+res.result.ticketId : "?ticket="+res.result.ticketId
        if (res.result.ticketId) this.redirectUri = this.setQueryVariable(this.redirectUri, "ticket", res.result.ticketId)
        console.log("getticket url:", this.redirectUri)
        location.href = this.redirectUri || '/'
      }).catch(e => {
        console.log("quick login error:", e)
      })
    },
    async sendCode(type) {
      if (this.times == 0) {
        this.sendLoading = true;
        //注册验证码发送之前，需要验证手机号是否已经注册过
        if (type == 1) {
          let res = await checkPhone(this.regForm.phone)
          if (res.code != 200) {
            this.sendLoading = false;
            return this.$message.error(res.message)
          }
        }
        sendCode({
          phone: type == 1 ? this.regForm.phone : type == 2 ? this.loginForm.username : this.forgetPwdForm.phone,
          scene: type
        }).then(res => {
          this.sendLoading = false;
          if (res.success) {
            this.$notify({
              title: '系统提示',
              message: res.message || '发送成功',
              type: 'success'
            });
            this.times = 59;
            this.setI = setInterval(() => {
              this.times -= 1;
              if (this.times == 0) {
                clearInterval(this.setI);
              }
            }, 1000);
          } else {
            this.$notify({
              title: '系统提示',
              message: res.message || '登录失败：未知错误',
              type: 'warning'
            });
          }
        }, error => {
          this.sendLoading = false;
          this.$notify({
            title: '系统提示',
            message: error || '登录失败：未知错误',
            type: 'warning'
          });
        })
      }
    },
    initAccountList(isForget) {
      let phone = isForget ? this.forgetPwdForm.phone : this.loginForm.username
      if (phone && /^1[0-9]{10}$/.test(phone)) {
        getAccounts({ phone: phone }).then(res => {
          if (res.success) {
            this.accountList = res.result
            if (this.accountList && this.accountList.length > 1) {
              if (isForget) this.forgetPwdForm.userId = this.accountList[0].id
              else this.loginForm.userId = this.accountList[0].id
            }
          }
        })
      }
      else this.accountList = null
    },
    login() {
      const formName = this.currentTag == 1 ? 'loginForm' : 'loginOtherForm'
      this.$refs[formName].validate(isOk => {
        if (isOk) {
          let params = {}
          if (this.currentTag == 1) {
            params = {
              username: this.loginForm.username,
              password: this.loginForm.password
            }
          } else {
            params = {
              username: this.loginForm.username,
              smsCode: this.loginForm.smsCode
            }
          }

          if (this.loginForm.userId) params.userId = this.loginForm.userId
          //this.chooseAccountVisible = true
          this.loginApi(params)

        }
      });
    },
    loginApi(params) {
      login(params).then((res) => {
        if (res.success) {
          //是否是多个账号 是多个账号需要选择多个账号
          if (res.has_multi_account) {
            //this.currentTag = 4
            this.accountList = res.result.datas
            return
          }



          this.$notify({
            title: '系统提示',
            message: res.message || '登录成功',
            type: 'success'
          });
          //protal 用户信息储存
          //alert(res.result.userInfo.city)
          this.$store.dispatch('LoginUser', res.result)
          //if(this.$store.state.siteConfigs.isInit) await this.$store.dispatch('InitUserRole')

          //如果是protal就直接跳转
          console.log("%c" + process.env.VUE_APP_PROTAL, "color: blue; font-size: 16px")
          console.log("%c-------------login:" + this.redirectUri, "color: blue; font-size: 16px")
          console.log("%c" + this.redirectUri.indexOf(process.env.VUE_APP_PROTAL) > -1, "color: red; font-size: 20px")
          if (this.redirectUri && this.redirectUri.indexOf(process.env.VUE_APP_PROTAL) > -1) return location.href = this.redirectUri

          //处理别的来源的登录
          console.log("############login:", res.result.ticketId)
          if (this.redirectUri && res.result && res.result.ticketId) {
            //this.redirectUri += this.redirectUri.indexOf("?")>-1 ? "&ticket="+res.result.ticketId : "?ticket="+res.result.ticketId
            this.redirectUri = this.setQueryVariable(this.redirectUri, "ticket", res.result.ticketId)
            location.href = this.redirectUri
          }
          else {
            location.href = '/'
          }
        } else {
          this.$notify({
            title: '系统提示',
            message: res.message || '登录失败：未知错误',
            type: 'warning'
          });
        }
      }, error => {
        this.$notify({
          title: '系统提示',
          message: error || '登录失败：未知错误',
          type: 'warning'
        });
      })
    },
    reg() {
      this.$refs.regForm.validate(isOk => {
        if (isOk) {
          if(this.redirectUri) this.regForm.siteDomain = this.redirectUri.split('?')[0]
          reg(this.regForm).then(res => {
            if (res.success) {
              this.$notify({
                title: '系统提示',
                message: res.message || '注册成功',
                type: 'success'
              });
              this.toLogin();
            } else {
              this.$notify({
                title: '系统提示',
                message: res.message || '注册失败：未知错误',
                type: 'warning'
              });
            }
          }, error => {
            this.$notify({
              title: '系统提示',
              message: error || '注册失败：未知错误',
              type: 'warning'
            });
          })
        }
      });
    },
    toReg() {
      this.currentType = 'reg';
    },
    toLogin() {
      this.currentType = 'login';
    },
    toForgetPass() {
      this.currentType = 'pwd'
    },
    postPhonePwdOne() {
      this.$refs.forgetPwdForm.validate(isOk => {
        if (isOk) {
          let params = { phone: this.forgetPwdForm.phone, smsCode: this.forgetPwdForm.smsCode, userId: this.forgetPwdForm.userId };
          forgetPwd(this.currentForgetPwdTag == 1 ? params : this.forgetPwdForm).then(res => {
            if (res.success) {
              if (this.currentForgetPwdTag == 1) {
                this.forgetPwdForm.prevToken = res.message;
                this.currentForgetPwdTag = 2;
              } else {
                this.$notify({
                  title: '系统提示',
                  message: res.message || '修改密码成功',
                  type: 'success'
                });
                this.toLogin();
              }
            } else {
              if (res.code == 500 && res.message == '您填写的手机号不存在') {
                this.showNoRegTip = true;
              } else {
                this.$notify({
                  title: '系统提示',
                  message: res.message || '验证失败：未知错误',
                  type: 'warning'
                });
              }
            }
          })
        }
      })
    }
  },
};
</script>
<style lang="scss">
.login-base .el-form-item {
  margin-bottom: 28px;
}
.login-base .el-form-item__error {
  white-space: initial;
}
.login-base .el-input__inner {
  border: 1px solid rgba(187, 187, 187, 100);
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  border-radius: 10px;
}
.login-base .el-input__inner:focus {
  border: 1px solid #6863ff;
  // box-shadow: 0px 0px 3px gray;
  border-radius: 10px;
}
.login-base .el-button--primary {
  background-color: #6964fe;
  border-color: #6964fe;
  border-radius: 10px;
  font-size: 24px;
  height: 60px;
}
.login-base .el-button--primary.is-disabled,
.login-base .el-button--primary.is-disabled:active,
.login-base .el-button--primary.is-disabled:focus,
.login-base .el-button--primary.is-disabled:hover {
  background-color: #a09df5;
  border-color: #a09df5;
}
.login-base .el-input .el-input__clear {
  font-size: 20px;
}
.login-base .el-input__suffix {
  right: 10px;
}
.login-base .dialog-footer .el-button--primary {
  font-size: 20px;
  height: 53px;
  padding: 10px 35px;
  margin-bottom: 30px;
}
.ma-select {
  .el-select-dropdown__item {
    height: 60px !important;
    line-height: 60px !important;
    // &:first-child{background: red;}
  }
  .user-logo {
    width: 45px;
    height: 45px;
    margin-right: 8px;
    overflow: visible;
    .el-image__inner {
      border-radius: 100%;
      margin-top: 7px;
    }
    // .image-slot img{
    //   width: 45px;
    //   height: 45px;
    // }
  }
}
</style>
<style lang="scss" scoped>
@import '~@/assets/css/views/user-login.scss';
</style>
